<template>
	<ConsentCollectionLayout
		title="Consent Collection Methods"
		caption="From here you can create, edit and manage your consent collection methods"
	>
		<template #content>
			<v-row>
				<v-col
					v-if="userCanSeePreferencePagesAndActionLinks && customPreferencePagesEnabled"
					class="d-flex justify-center"
					cols="6"
					xl="4"
				>
					<OverviewCard
						height="350px"
						card-title="Custom Preference Pages"
						:user-can-create-and-update="userCanCreateAndUpdatePreferencePagesAndActionLinks"
						@overview="$router.push({ name: PREFERENCE_PAGE_LINKS })"
						@create="$router.push({
							name: MANAGE_PREFERENCE_LINK,
							query: {
								linkType: PREFERENCE_PAGE_LINK_TYPE
							},
							params: {
								accessedViaHub: true
							}
						})"
					>
						<template #body>
							Build your own Preference Pages fully controlled by your CSS
						</template>
						<template #image>
							<v-img
								src="../../../assets/images/original-colours/Custom-Preference-Pages.svg"
								alt="Custom Preference Page"
								max-height="100%"
								max-width="270px"
								height="100%"
							/>
						</template>
					</OverviewCard>
				</v-col>
				<v-col
					v-if="userCanSeePreferencePagesAndActionLinks && dragAndDropPreferencePagesEnabled"
					class="d-flex justify-center"
					cols="6"
					xl="4"
				>
					<OverviewCard
						height="350px"
						card-title="Drag-and-Drop Preference Pages"
						:user-can-create-and-update="userCanCreateAndUpdatePreferencePagesAndActionLinks"
						@overview="$router.push({ name: CONSENT_COLLECTION_PREFERENCE_PAGES })"
						@create="$router.push({
							name: MANAGE_CONSENT_COLLECTION_PREFERENCE_PAGE,
							params: {
								userFullPermissions: userCanCreateAndUpdatePreferencePagesAndActionLinks
							}
						})"
					>
						<template #body>
							Build your own Preference Pages with our no-code editor
						</template>
						<template #image>
							<v-img
								src="../../../assets/images/original-colours/Drag-and-Drop-Preference-Pages.svg"
								alt="Preference Page"
								max-height="100%"
								max-width="270px"
								height="100%"
							/>
						</template>
					</OverviewCard>
				</v-col>
				<v-col
					v-if="userCanSeeWidgets && embeddedConsentCollectionEnabled"
					class="d-flex justify-center"
					cols="6"
					xl="4"
				>
					<OverviewCard
						height="350px"
						card-title="Embedded Consent Collection"
						:user-can-create-and-update="userCanCreateAndUpdateWidgets"
						@overview="$router.push({ name: PREFERENCE_WIDGETS })"
						@create="$router.push({
							name: MANAGE_PREFERENCE_WIDGET,
							params: {
								accessedViaHub: true,
								userFullPermissions: userCanCreateAndUpdateWidgets
							}
						})"
					>
						<template #body>
							Build Consent Collection Points to embed within your existing forms
						</template>
						<template #image>
							<v-img
								src="../../../assets/images/original-colours/Embedded-Consent-Collection.svg"
								alt="Embedded Consent Collection"
								max-height="100%"
								max-width="270px"
								height="100%"
							/>
						</template>
					</OverviewCard>
				</v-col>
				<v-col
					v-if="userCanSeeWidgets && consentCollectionWidgetEnabled"
					class="d-flex justify-center"
					cols="6"
					xl="4"
				>
					<OverviewCard
						height="350px"
						card-title="Consent Collection Widget"
						:user-can-create-and-update="userCanCreateAndUpdateWidgets"
						@overview="$router.push({ name: CONSENT_COLLECTION_WIDGETS })"
						@create="$router.push({
							name: MANAGE_CONSENT_COLLECTION_WIDGET,
							params: {
								userFullPermissions: userCanCreateAndUpdateWidgets
							}
						})"
					>
						<template #body>
							Build Consent Collection Widgets that can be applied to your existing pages
						</template>
						<template #image>
							<v-img
								src="../../../assets/images/original-colours/Consent-Collection-Widget.svg"
								alt="Consent Collection Widget"
								max-height="100%"
								max-width="270px"
								height="100%"
							/>
						</template>
					</OverviewCard>
				</v-col>
				<v-col
					v-if="userCanSeePreferencePagesAndActionLinks"
					class="d-flex justify-center"
					cols="6"
					xl="4"
				>
					<OverviewCard
						height="350px"
						card-title="Action Links"
						:user-can-create-and-update="userCanCreateAndUpdatePreferencePagesAndActionLinks"
						@overview="$router.push({ name: ACTION_LINKS })"
						@create="$router.push({
							name: MANAGE_PREFERENCE_LINK,
							query: {
								linkType: ACTION_LINK_TYPE
							},
							params: {
								accessedViaHub: true
							}
						})"
					>
						<template #body>
							Create links that set your customers consents and preferences
						</template>
						<template #image>
							<v-img
								src="../../../assets/images/original-colours/Action-Links.svg"
								alt="Action Links"
								max-height="100%"
								max-width="270px"
								height="100%"
							/>
						</template>
					</OverviewCard>
				</v-col>
				<v-col
					v-if="userCanSeeProgressiveProfiling"
					class="d-flex justify-center"
					cols="6"
					xl="4"
				>
					<OverviewCard
						height="350px"
						card-title="Progressive Profiling"
						:user-can-create-and-update="userCanCreateAndUpdateProgressiveProfiling"
						@overview="$router.push({ name: PROGRESSIVE_PROFILING })"
						@create="$router.push({
							name: MANAGE_PROGRESSIVE_PROFILE,
							params: {
								userCanCreateAndUpdate: userCanCreateAndUpdateProgressiveProfiling,
								accessedViaHub: true
							}
						})"
					>
						<template #body>
							Create pop-ups (e.g. questions, surveys or polls) to build anonymous preference profiles
						</template>
						<template #image>
							<v-img
								src="../../../assets/images/original-colours/Progressive-Profiling.svg"
								alt="Progressive Profiling"
								max-height="100%"
								max-width="270px"
								height="100%"
							/>
						</template>
					</OverviewCard>
				</v-col>
			</v-row>
		</template>
	</ConsentCollectionLayout>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ConsentCollectionLayout from './consent-collection-layout.vue'
import OverviewCard from '../../../../../shared/components/overview-card.vue'
import {
	PREFERENCE_PAGE_LINKS,
	ACTION_LINKS,
	MANAGE_PREFERENCE_LINK,
	PROGRESSIVE_PROFILING,
	MANAGE_PROGRESSIVE_PROFILE,
	PREFERENCE_WIDGETS,
	MANAGE_PREFERENCE_WIDGET,
	CONSENT_COLLECTION_WIDGETS,
	CONSENT_COLLECTION_PREFERENCE_PAGES,
	MANAGE_CONSENT_COLLECTION_WIDGET,
	MANAGE_CONSENT_COLLECTION_PREFERENCE_PAGE
} from '../../../router/route-names.js'
import { getProgressiveProfiles } from '../../../../../shared/utils/api/progressive-profiling.js'
import { getPreferenceWidgets } from '../../../../../shared/utils/api/preference-widgets.js'
import {
	CAN_CREATE_UPDATE_PROGRESSIVE_PROFILING,
	CAN_SEE_PROGRESSIVE_PROFILING,
	SEE_PREF_WIDGETS_AREA,
	PREF_WIDGETS_AREA_FULL_PERMISSIONS,
	LINKS_AREA_FULL_PERMISSIONS,
	LINKS_PAGE_FULL_PERMISSIONS,
	SEE_LINKS_AREA
} from '../../../../../shared/permissions/admin-portal-permissions.js'
import { enableConsentCollectionPreferencePageOptions, enableConsentCollectionWidgetOptions } from '../../../../../shared/state/config-keys.js'
export default {
	components: {
		ConsentCollectionLayout,
		OverviewCard
	},
	setup () {
		return {
			PREFERENCE_PAGE_LINKS,
			ACTION_LINKS,
			MANAGE_PREFERENCE_LINK,
			PROGRESSIVE_PROFILING,
			MANAGE_PROGRESSIVE_PROFILE,
			PREFERENCE_WIDGETS,
			MANAGE_PREFERENCE_WIDGET,
			CONSENT_COLLECTION_WIDGETS,
			CONSENT_COLLECTION_PREFERENCE_PAGES,
			MANAGE_CONSENT_COLLECTION_WIDGET,
			MANAGE_CONSENT_COLLECTION_PREFERENCE_PAGE,
			enableConsentCollectionPreferencePageOptions,
			enableConsentCollectionWidgetOptions
		}
	},
	data () {
		const ACTION_LINK_TYPE = 1
		const PREFERENCE_PAGE_LINK_TYPE = 3
		return {
			ACTION_LINK_TYPE,
			PREFERENCE_PAGE_LINK_TYPE
		}
	},
	computed: {
		...mapGetters('auth', ['productAreaPermission']),
		userCanSeeProgressiveProfiling () {
			return this.productAreaPermission(CAN_SEE_PROGRESSIVE_PROFILING)
		},
		userCanCreateAndUpdateProgressiveProfiling () {
			return this.productAreaPermission(CAN_CREATE_UPDATE_PROGRESSIVE_PROFILING)
		},
		userCanSeeWidgets () {
			return this.productAreaPermission(SEE_PREF_WIDGETS_AREA)
		},
		userCanCreateAndUpdateWidgets () {
			return this.productAreaPermission(PREF_WIDGETS_AREA_FULL_PERMISSIONS)
		},
		userCanSeePreferencePagesAndActionLinks () {
			return this.productAreaPermission(SEE_LINKS_AREA)
		},
		userCanCreateAndUpdatePreferencePagesAndActionLinks () {
			return this.productAreaPermission(LINKS_AREA_FULL_PERMISSIONS) || this.productAreaPermission(LINKS_PAGE_FULL_PERMISSIONS)
		},
		customPreferencePagesEnabled () {
			return ['0', '2'].includes(enableConsentCollectionPreferencePageOptions.value)
		},
		dragAndDropPreferencePagesEnabled () {
			return ['1', '2'].includes(enableConsentCollectionPreferencePageOptions.value)
		},
		embeddedConsentCollectionEnabled () {
			return ['0', '2'].includes(enableConsentCollectionWidgetOptions.value)
		},
		consentCollectionWidgetEnabled () {
			return ['1', '2'].includes(enableConsentCollectionWidgetOptions.value)
		}
	},
	async created () {
		this.loadProgressiveProfiling()
		this.loadPreferenceWidgets()
	},
	methods: {
		...mapActions('lookupData', ['storeProgressiveProfilingLookupData', 'storePreferenceWidgetLookupData']),
		async loadProgressiveProfiling () {
			const { lookupData } = await getProgressiveProfiles()
			this.storeProgressiveProfilingLookupData(lookupData)
		},
		async loadPreferenceWidgets () {
			const { lookupData } = await getPreferenceWidgets()
			this.storePreferenceWidgetLookupData(lookupData)
		}
	}
}
</script>
