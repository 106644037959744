<template>
	<v-card
		class="d-flex"
		:height="height"
		max-width="600"
		width="100%"
	>
		<v-col
			cols="5"
			class="pa-0"
		>
			<slot name="image" />
		</v-col>
		<v-col
			cols="7"
			class="d-flex flex-column"
		>
			<v-card-title
				class="pb-3 text-break"
			>
				{{ cardTitle }}
				<v-spacer />
				<v-tooltip
					v-if="helpText"
					slot="append"
					right
				>
					<template #activator="{ on }">
						<v-icon
							light
							v-on="on"
						>
							mdi-help-circle
						</v-icon>
					</template>
					<span>
						{{ helpText }}
					</span>
				</v-tooltip>
			</v-card-title>
			<v-divider />
			<v-card-text class="cassie-vertical-md flex-fill">
				<slot name="body" />
			</v-card-text>
			<v-card-actions>
				<v-row dense>
					<v-col
						cols="12"
					>
						<v-spacer />
						<SecondaryActionButton
							class="cassie-input-width-xl"
							@click="$emit('overview')"
						>
							{{ overviewButtonText }}
						</SecondaryActionButton>
						<PrimaryActionButton
							v-if="userCanCreateAndUpdate"
							full-width
							class="mt-2 cassie-input-width-xl"
							@click="$emit('create')"
						>
							{{ createButtonText }}
						</PrimaryActionButton>
					</v-col>
				</v-row>
			</v-card-actions>
		</v-col>
	</v-card>
</template>

<script>
import PrimaryActionButton from './primary-action-button.vue'
import SecondaryActionButton from './secondary-action-button.vue'

export default {
	name: 'overview-card',
	components: {
		PrimaryActionButton,
		SecondaryActionButton
	},
	props: {
		cardTitle: {
			type: String,
			required: true
		},
		overviewButtonText: {
			type: String,
			default: 'Overview'
		},
		createButtonText: {
			type: String,
			default: 'Create New'
		},
		userCanCreateAndUpdate: {
			type: Boolean,
			default: false
		},
		helpText: String,
		height: {
			type: String,
			default: 'auto'
		}
	}
}
</script>
